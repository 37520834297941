@import "@styles/variables";

* {
    font-family: 'Nunito', sans-serif; 
}

html {
    ::-webkit-scrollbar {
        display: none;
    }
}

body {
    margin: 0;
    color: #6F4E37;
    background: $white;
}

h2, p {
    margin: unset;
}

.coffee-button, .coffee-filled-label {
    color: $white;
    background-color: $coffee-light;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 14px;
    font-weight: 400;
}

.caramel-button, .caramel-filled-label {
    color: $white;
    background-color: $caramel;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 14px;
    font-weight: 400;
}

.hide-caret {
    &::after {
        content: none !important;
    }
}