@import "@styles/variables";
@import "@libs/typed/typed.scss";

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#intro {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 3rem 5rem 0rem;

    img {
        height: 700px;
        filter: drop-shadow(0px 0px 5px rgb(0, 0, 0, 0.5));
    }

    .headings {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &>div {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }

        #hello-world {
            @include typed("Hello world,", (type: 0.08), (iterations: 0, caret-width: 2px));
        }

        #name.write {
            font-weight: 400;
            font-size: 4rem;
            margin: 0rem;
            color: $coffee;
            font-family: 'Lora', serif;
            @include typed("I'm Chloe", (type: 0.08), (iterations: 0, caret-width: 2px, delay: 0.2));
        }
    }

    .subheadings {
        display: flex;
        flex-wrap: wrap;
        width: 800px;
        gap: 1rem;
        font-weight: 400;
        font-size: 0.8rem;
        margin-top: 2rem;

        a {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 1rem;
        }

        h2 {
            opacity: 0;
            animation: fadeIn 1s ease-in forwards;
        }

        h2:nth-child(1) {
            animation-delay: 5s;
        }

        h2:nth-child(2) {
            animation-delay: 6s;
        }

        h2:nth-child(3) {
            animation-delay: 7s;
        }
    }

    span {
        font-size: 2rem;
        font-weight: 500;
    }
}

#about {
    background-color: $coffee;
    color: $white;
    padding: 5rem;
}