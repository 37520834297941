@import "@styles/variables";

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 4rem;

    #logo {
        font-size: 2rem;
        font-weight: bold;
    }

    #main-nav {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: 1.2rem;
    }

    a {
        text-decoration: none;
        color: $coffee;
        font-weight: 500;

        &.active {
            font-weight: 900;
        }
    }
}